// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type="file"] {
  position: relative;
  width: 100%;
  margin-top: 2px;
  height: calc(1.5em + 1rem + 2px);
  font-size: 1rem;
  font-weight: 400;
}

input[type="file"]::file-selector-button {
  width: 120px;
  color: transparent;
}


input[type="file"]::after {
  position: absolute;
  pointer-events: none;
  top: 7px;
  left: 20px;
  color: #0964b0;
  content: "Upload File";
}

/* ------- From Step 1 ------- */

/* file upload button */
input[type="file"]::file-selector-button {
  border-radius: 4px;
  
  height: 40px;
  cursor: pointer;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-right: 16px;
  transition: background-color 200ms;
}

/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
  background-color: #f3f4f6;
}

/* file upload button active state */
input[type="file"]::file-selector-button:active {
  background-color: #e5e7eb;
}
`, "",{"version":3,"sources":["webpack://./src/css/CreateApplication.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,gCAAgC;EAChC,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;;AAGA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,QAAQ;EACR,UAAU;EACV,cAAc;EACd,sBAAsB;AACxB;;AAEA,gCAAgC;;AAEhC,uBAAuB;AACvB;EACE,kBAAkB;;EAElB,YAAY;EACZ,eAAe;EACf,uBAAuB;EACvB,qCAAqC;EACrC,2CAA2C;EAC3C,kBAAkB;EAClB,kCAAkC;AACpC;;AAEA,mCAAmC;AACnC;EACE,yBAAyB;AAC3B;;AAEA,oCAAoC;AACpC;EACE,yBAAyB;AAC3B","sourcesContent":["input[type=\"file\"] {\n  position: relative;\n  width: 100%;\n  margin-top: 2px;\n  height: calc(1.5em + 1rem + 2px);\n  font-size: 1rem;\n  font-weight: 400;\n}\n\ninput[type=\"file\"]::file-selector-button {\n  width: 120px;\n  color: transparent;\n}\n\n\ninput[type=\"file\"]::after {\n  position: absolute;\n  pointer-events: none;\n  top: 7px;\n  left: 20px;\n  color: #0964b0;\n  content: \"Upload File\";\n}\n\n/* ------- From Step 1 ------- */\n\n/* file upload button */\ninput[type=\"file\"]::file-selector-button {\n  border-radius: 4px;\n  \n  height: 40px;\n  cursor: pointer;\n  background-color: white;\n  border: 1px solid rgba(0, 0, 0, 0.16);\n  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);\n  margin-right: 16px;\n  transition: background-color 200ms;\n}\n\n/* file upload button hover state */\ninput[type=\"file\"]::file-selector-button:hover {\n  background-color: #f3f4f6;\n}\n\n/* file upload button active state */\ninput[type=\"file\"]::file-selector-button:active {\n  background-color: #e5e7eb;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
